<template>
    <Component
        :is="_tag"
        :disabled="disabled || status"
        :class="[
            variant, _colour,
            _hover ? `hover:${_hover}` : null,
            _activeColour ? `active:${_activeColour}` : null,
            _iconPos && !hideIcon ? `icon-${_iconPos}` : null,
            { 
                active, slim, disabled, skeleton, fill, touch,
                icononly: !hasLabel,
                custom: isCustomColour,
                hovering: isHovering,
                'popup-unstyle': status
            }
        ]"
        :type="type"
        :style="{
            width, height,
            '--button-color': isCustomColour ? colour : null,
            '--button-contrast-color': isCustomColour ? contrastColour : null,
            '--button-hover-color': isCustomColour ? hoverColour : null,
            '--button-shadow': isCustomColour ? shadow : null
        }"
        :href="href"
        class="cobutton"
        :tabindex="tabindex"
        v-bind="$attrs"
        @click="$emit('click', $event)"
        @keydown="$emit('keydown', $event)"
        @mouseenter="isHovering = true; $emit('mouseenter', $event);"
        @mouseleave="isHovering = false; $emit('mouseleave', $event);"
    >
        <Spinner
            v-if="status && !hideIcon"
            :success="status === 'success'"
            :error="status === 'error' || status === 'warning'"
            :size="iconSize"
            :success-icon="type === 'basic' ? 'correct-circle' : 'correct'"
        />
        <Icon
            v-else-if="!status && _icon && !hideIcon"
            :name="_icon"
            :label="iconLabel"
            :size="iconSize"
        />
        <template v-if="showLabel && $slots.default">
            <Txt
                v-if="hasTextProp"
                :heading="heading"
                :size="size"
                :clamp="clamp"
                :select="false"
            >
                <slot v-bind="{ hovering: isHovering }" />
            </Txt>
            <slot v-else v-bind="{ hovering: isHovering }" />
        </template>
    </Component>
</template>

<script>
import { hexToRgb, readable, shadow, shade1 } from '@/helpers/colour';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Spinner from '@/components/animation/spinner';

const checkNodeContent = node => {
    const text = node.text ? node.text : (typeof node.children === 'string' ? node.children : '');
    const hasLabel = node.type.name === 'Txt' || !!text && !text.includes('v-if');
    if (!hasLabel && node.children && Array.isArray(node.children) && node.children[0]) return checkNodeContent(node.children[0]);
    return hasLabel;
};

export default {
    name: 'Btn',
    components: { Txt, Icon, Spinner },
    inject: {
        tabindex: {
            default: null
        }
    },
    inheritAttrs: false,
    props: {
        tag: { type: String, default: null },
        type: { type: String, default: 'button' },
        href: { type: String, default: null },
        status: { type: String, default: null, validator: status => [ 'waiting', 'success', 'error' ].includes(status), options: 'status' },

        variant: { type: String, default: 'default', validator: variant => [ 'default', 'basic', 'ghost', 'create', 'add', 'media', 'context', 'dropdown' ].includes(variant), options: 'buttonVariant' },
        colour: { type: String, default: 'primary', options: 'buttonColour' },
        hover: { type: String, default: null, options: 'buttonColour' },
        activeColour: { type: String, default: null, options: 'buttonColour' },

        icon: { type: String, default: null, options: 'icon' },
        iconPos: { type: String, default: null, options: ['left', 'right', 'none'] },
        iconSize: { type: String, default: '24' },
        iconType: { type: String, default: null },
        iconLabel: { type: String, default: null },
        hideIcon: { type: Boolean, default: false },

        heading: { type: Boolean, default: false },
        size: { type: String, default: null, options: 'textSize' },
        clamp: { type: Boolean, default: false },

        slim: { type: Boolean, default: false },
        fill: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        skeleton: { type: Boolean, default: false },
        active: { type: Boolean, default: false },
        forceLabel: { type: Boolean, default: false }
    },
    emits: ['click', 'keydown', 'mouseenter', 'mouseleave', 'resize'],
    slots: ['default'],
    data() {
        return {
            width: null,
            height: null,
            hasLabel: true,
            isHovering: false
        };
    },
    computed: {
        _tag() {
            if (this.tag) return this.tag;
            return this.href ? 'a' : 'button';
        },
        touch() {
            return this.$browser && this.$browser.is.touch;
        },
        _icon() {
            if (this.icon) return this.icon;
            if (this.variant === 'add') return 'plus';

            return null;
        },
        _iconPos() {
            if (!this._icon || !this.hasLabel) return null;
            if (this.iconPos) return this.iconPos;
            if (this.variant === 'media') return;
            if (this.variant === 'create') return 'left';
            if (this.variant === 'add') return 'left';
            if (this.hasLabel) return 'right';

            return null;
        },
        _colour() {
            if (this.status === 'waiting') return 'faded';
            if (this.status === 'success') return 'success';
            if (this.status === 'error') return 'danger';
            if (this.status === 'warning') return 'warning';
            if (this.isCustomColour) return;

            return this.colour;
        },
        _hover() {
            if (this.hover === 'none') return;
            if (this.isCustomColour) return;
            return this.hover || this.colour;
        },
        _activeColour() {
            if (this.activeColour === 'none') return;
            if (this.isCustomColour) return;
            return this.activeColour || this.hover || this.colour;
        },
        showLabel() {
            if (this.type === 'basic') return true;
            return !this.status;
        },
        hasTextProp() {
            return !!(this.heading || this.size);
        },
        isCustomColour() {
            return this.colour
                && this.colour.includes('#');
        },
        contrastColour() {
            if (!this.isCustomColour) return;
            return readable(hexToRgb(this.colour)).contrast;
        },
        hoverColour() {
            if (!this.isCustomColour) return;
            return shade1(hexToRgb(this.colour));
        },
        shadow() {
            if (!this.isCustomColour) return;
            return shadow(this.colour);
        }
    },
    watch: {
        status(to, from) {
            if (this.type === 'basic') return;
            if (to && !from) {
                this.width = this.$el.clientWidth + 'px';
                this.height = this.$el.clientHeight + 'px';
            } else if (!to) {
                this.width = null;
                this.height = null;
            }
        }
    },
    mounted() {
        this.hasLabel = this.checkForSlotContent();
    },
    beforeUpdate() {
        this.hasLabel = this.checkForSlotContent();
    },
    methods: {
        checkForSlotContent() {
            if (this.forceLabel) return true;
            return this.$slots.default
                && this.$slots.default().some(checkNodeContent);
        }
    }
};
</script>

<style lang="less">
.cobutton {
    margin: 0;
    border: none;
    appearance: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    transition: var(--smooth-fast), border-radius 0s linear, width 0s linear;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    letter-spacing: -0.2px;

    h1, h2, h3, h4, h5, h6, p { margin: 0; }
    &.checkbox + .checkbox { margin-top: 0; }
    &:not(.touch):hover { text-decoration: none; }
    &:focus-visible {
        outline: 2px solid var(--focus-colour, var(--coal));
        outline-offset: 2px;
    }
    .txt-component:not(.clamp) > .txt-component-content {
        display: flex;
        align-items: center;
    }
    &.icon-right {
        flex-direction: row-reverse;
        span.icon { margin-left: 8px; }
    }
    &.icon-left {
        flex-direction: row;
        span.icon { margin-right: 8px; }
    }
    span.spinner { margin: auto; }
    &[target="_blank"] {
        position: relative;
        .icon.external { 
            position: absolute;
            right: 8px;
            opacity: 0;
        }
        &:hover {
            .icon.external {
                opacity: 1;
                fill: var(--anchor) !important;
            }
        }
    }
    &.fill {
        width: 100%;
        justify-content: center;
    }
    &.skeleton {
        border-radius: 2px;
        min-width: 120px;
        height: 24px;
        box-shadow: none;
        cursor: default;
        > * { opacity: 0; }
    }

    // Variants
    &.default, &.ghost {
        min-height: 40px;
        padding: 8px 16px;
        border-radius: 8px;
        &.slim {
            min-height: 32px;
            padding: 4px 12px;
            border-radius: 6px;
        }
    }
    &.create {
        justify-content: center;
        height: 40px;
        padding: 8px 16px;
        border-radius: 20px;
        &.icon-left  { padding: 8px 16px 8px 12px; }
        &.icon-right { padding: 8px 12px 8px 16px; }
        &.icononly {
            width: 40px;
            border-radius: 100%;
            &.slim {
                width: 32px;
                height: 32px;
            }
        }
        &.slim {
            height: 32px;
            padding: 8px;
            &.icon-left  { padding: 8px 12px 8px 8px; }
            &.icon-right { padding: 8px 8px 8px 12px; }
        }
    }
    &.default, &.create {
        &.custom {
            background-color: var(--button-color);
            border-color: var(--button-color);
            color: var(--button-contrast-color);
            fill: var(--button-contrast-color);
            > * {
                color: var(--button-contrast-color);
                fill: var(--button-contrast-color);
            }
            &:not(.touch):hover {
                background-color: var(--button-hover-color);
                border-color: var(--button-hover-color);
                color: var(--button-contrast-color);
                fill: var(--button-contrast-color);
                box-shadow: var(--button-shadow);
                > * {
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                }
            }
            &:active, &.active {
                background-color: var(--button-color);
                border-color: var(--button-color);
                color: var(--button-contrast-color);
                fill: var(--button-contrast-color);
                > * {
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                }
                &:not(.touch):hover {
                    background-color: var(--button-hover-color);
                    border-color: var(--button-hover-color);
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                    box-shadow: var(--button-shadow);
                    > * {
                        color: var(--button-contrast-color);
                        fill: var(--button-contrast-color);
                    }
                }
            }
        }
        &.translucent {
            background-color: rgba(255, 255, 255, 0.12);
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover { background-color: rgba(255, 255, 255, 0.4); }
            &:active, &.active {
                background-color: rgba(255, 255, 255, 0.5);
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
                &:not(.touch):hover { background-color: rgba(255, 255, 255, 0.56); }
            }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: rgba(255, 255, 255, 0.12);
                    > * {
                        color: rgba(255, 255, 255, 0.2);
                        fill: rgba(255, 255, 255, 0.2);
                    }
                }
                .badge {
                    background-color: rgba(255, 255, 255, 0.12);
                    > * {
                        color: rgba(255, 255, 255, 0.2);
                        fill: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
        &.translucent-dark {
            background-color: ~"rgba(var(--coal-rgb), 0.25)";
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover { background-color: ~"rgba(var(--coal-rgb), 0.35)"; }
            &:active, &.active { background-color: ~"rgba(var(--coal-rgb), 0.5)"; }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: ~"rgba(var(--coal-rgb), 0.25)";
                    > * {
                        color: ~"rgba(255, 255, 255, 0.2)";
                        fill: ~"rgba(255, 255, 255, 0.2)";
                    }
                }
            }
        }
        &.translucent-dark-border {
            background-color: ~"rgba(var(--coal-rgb), 0.25)";
            border: 1px solid rgba(255, 255, 255, 0.25);
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover {
                background-color: ~"rgba(var(--coal-rgb), 0.35)";
                border: 1px solid rgba(255, 255, 255, 0.4);
            }
            &:active, &.active {
                background-color: ~"rgba(var(--coal-rgb), 0.5)";
                border: 1px solid rgba(255, 255, 255, 0.5);
            }
            &:disabled, &.disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: ~"rgba(var(--coal-rgb), 0.25)";
                    > * {
                        color: ~"rgba(var(--coal-rgb), 0.4)";
                        fill: ~"rgba(var(--coal-rgb), 0.4)";
                    }
                }
            }
            &.border {
                border: 1px solid rgba(255, 255, 255, 0.25);
                &:not(.touch):hover { border: 1px solid rgba(255, 255, 255, 0.4); }
                &:active, &.active { border: 1px solid rgba(255, 255, 255, 0.5); }
            }
        }
        &.magic {
            background: linear-gradient(149deg, #6545FC 0%, #9044FC 100%);
            border-color: #6545FC;
            color: #FFF;
            fill: #FFF;
            > * {
                color: #FFF;
                fill: #FFF;
            }
        }
        &.hover\:magic {
            &:not(.touch):hover {
                background: linear-gradient(149deg, #4A2CD9 0%, #9C1EFF 100%), linear-gradient(149deg, #6545FC 0%, #9044FC 100%);
                border-color: #6545FC;
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
        }
        &.primary {
            background-color: var(--primary);
            border-color: var(--primary);
            color: var(--primary-contrast);
            fill: var(--primary-contrast);
            > *, > .chevron.icon {
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
            }
        }
        &.hover\:primary {
            &:not(.touch):hover {
                background-color: var(--primary);
                border-color: var(--primary);
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
            &.primary:not(.touch):hover {
                background-color: var(--primary-shade-1);
                border-color: var(--primary-shade-1);
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
                box-shadow: var(--primary-shadow);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
        }
        &.active\:primary {
            &:active, &.active {
                background-color: var(--primary);
                border-color: var(--primary);
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
                &:not(.touch):hover {
                    background-color: var(--primary-shade-1);
                    border-color: var(--primary-shade-1);
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                    box-shadow: var(--primary-shadow);
                    > * {
                        color: var(--primary-contrast);
                        fill: var(--primary-contrast);
                    }
                }
            }
        }
        &.fade-primary {
            background-color: var(--primary-tint);
            border-color: var(--primary-tint);
            color: var(--primary);
            fill: var(--primary);
            > * {
                color: var(--primary);
                fill: var(--primary);
            }
        }
        &.hover\:fade-primary {
            &:not(.touch):hover {
                background-color: var(--primary-tint-2);
                border-color: var(--primary-tint-2);
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
            &.primary:not(.touch):hover {
                background-color: var(--primary-tint-2);
                border-color: var(--primary-tint-2);
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }
        &.active\:fade-primary {
            &:active, &.active {
                background-color: var(--primary-tint);
                border-color: var(--primary-tint);
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
                &:not(.touch):hover {
                    background-color: var(--primary-tint-2);
                    border-color: var(--primary-tint-2);
                    color: var(--primary);
                    fill: var(--primary);
                    > * {
                        color: var(--primary);
                        fill: var(--primary);
                    }
                }
            }
        }
        &.complimentary {
            background-color: var(--complimentary);
            border-color: var(--complimentary);
            color: var(--complimentary-contrast);
            fill: var(--complimentary-contrast);
            > * {
                color: var(--complimentary-contrast);
                fill: var(--complimentary-contrast);
            }
        }
        &.hover\:complimentary {
            &:not(.touch):hover, &:active, &.active {
                background-color: var(--complimentary-shade-1);
                border-color: var(--complimentary-shade-1);
                color: var(--complimentary-contrast);
                fill: var(--complimentary-contrast);
                box-shadow: var(--complimentary-shadow);
                > * {
                    color: var(--complimentary-contrast);
                    fill: var(--complimentary-contrast);
                }
            }
        }
        &.success {
            background-color: var(--success);
            border-color: var(--success);
            color: var(--success-contrast);
            fill: var(--success-contrast);
            > * {
                color: var(--success-contrast);
                fill: var(--success-contrast);
            }
        }
        &.hover\:success {
            &:not(.touch):hover, &:active, &.active {
                background-color: var(--success-shade-1);
                border-color: var(--success-shade-1);
                color: var(--success-contrast);
                fill: var(--success-contrast);
                box-shadow: var(--success-shadow);
                > * {
                    color: var(--success-contrast);
                    fill: var(--success-contrast);
                }
            }
        }
        &.warning {
            background-color: var(--warning);
            border-color: var(--warning);
            color: var(--warning-contrast);
            fill: var(--warning-contrast);
            > * {
                color: var(--warning-contrast);
                fill: var(--warning-contrast);
            }
        }
        &.hover\:warning {
            &:not(.touch):hover, &:active, &.active {
                background-color: var(--warning-shade-1);
                border-color: var(--warning-shade-1);
                color: var(--warning-contrast);
                fill: var(--warning-contrast);
                box-shadow: var(--warning-shadow);
                > * {
                    color: var(--warning-contrast);
                    fill: var(--warning-contrast);
                }
            }
        }
        &.danger {
            background-color: var(--danger);
            border-color: var(--danger);
            color: var(--danger-contrast);
            fill: var(--danger-contrast);
            > * {
                color: var(--danger-contrast);
                fill: var(--danger-contrast);
            }
        }
        &.hover\:danger {
            &:not(.touch):hover, &:active, &.active {
                background-color: var(--danger);
                border-color: var(--danger);
                color: var(--danger-contrast);
                fill: var(--danger-contrast);
                > * {
                    color: var(--danger-contrast);
                    fill: var(--danger-contrast);
                }
            }
            &.danger {
                &:not(.touch):hover, &:active, &.active {
                    background-color: var(--danger-shade-1);
                    border-color: var(--danger-shade-1);
                    box-shadow: var(--danger-shadow);
                }
            }
        }
        &.cloud {
            background-color: var(--cloud);
            border-color: var(--cloud);
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:cloud {
            &:not(.touch):hover, &:active, &.active {
                background-color: var(--smoke);
                border-color: var(--smoke);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.smoke {
            background-color: var(--smoke);
            border-color: var(--smoke);
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:smoke {
            &:not(.touch):hover {
                background-color: var(--smoke);
                border-color: var(--smoke);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
            &.smoke {
                &:not(.touch):hover {
                    background-color: var(--mercury);
                    border-color: var(--mercury);
                    box-shadow: var(--shadow-base);
                }
            }
        }
        &.active\:smoke {
            &:active, &.active {
                background-color: var(--smoke);
                border-color: var(--smoke);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
                &:not(.touch):hover {
                    background-color: var(--mercury);
                    border-color: var(--mercury);
                    color: var(--coal);
                    fill: var(--coal);
                    box-shadow: var(--shadow-base);
                    > * {
                        color: var(--coal);
                        fill: var(--coal);
                    }
                }
            }
        }
        &.contrast {
            background-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
            border-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
            color: rgb(var(--colour-contrast-rgb));
            fill: rgb(var(--colour-contrast-rgb));
            > * {
                color: rgb(var(--colour-contrast-rgb));
                fill: rgb(var(--colour-contrast-rgb));
            }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.25)";
                    > * {
                        color: ~"rgba(var(--colour-contrast-rgb, var(--coal-rgb)), 0.5)";
                        fill: ~"rgba(var(--colour-contrast-rgb, var(--coal-rgb)), 0.5)";
                    }
                }
            }
        }
        &.hover\:contrast {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.6)";
                border-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.6)";
            }
        }
        &.white {
            background-color: #FFF;
            border-color: #FFF;
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
            &.active:not(.checkbox) {
                background-color: var(--mercury);
                border-color: var(--mercury);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            
            }
        }
        &.hover\:white {
            &:not(.touch):hover {
                background-color: var(--cloud);
                border-color: var(--cloud);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.anchor {
            > * {
                color: var(--anchor);
                fill: var(--anchor);
            }
        }
    }

    &.ghost {
        background: none;
        border-width: 1px;
        border-style: solid;
        padding: 11px 16px;

        &.custom {
            border-color: var(--button-color);
            color: var(--button-color);
            fill: var(--button-color);
            > * {
                color: var(--button-color);
                fill: var(--button-color);
            }
            &:not(.touch):hover {
                background-color: var(--button-color);
                border-color: var(--button-color);
                color: var(--button-contrast-color);
                fill: var(--button-contrast-color);
                > * {
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                }
            }
            &:active, &.active {
                background-color: var(--button-color);
                border-color: var(--button-color);
                color: var(--button-contrast-color);
                fill: var(--button-contrast-color);
                > * {
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                }
                &:not(.touch):hover {
                    background-color: var(--button-color);
                    border-color: var(--button-color);
                    color: var(--button-contrast-color);
                    fill: var(--button-contrast-color);
                    > * {
                        color: var(--button-contrast-color);
                        fill: var(--button-contrast-color);
                    }
                }
            }
        }
        &.primary {
            border-color: var(--primary);
            color: var(--primary);
            fill: var(--primary);
            > * {
                color: var(--primary);
                fill: var(--primary);
            }
        }
        &.hover\:primary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: var(--primary);
                border-color: var(--primary);
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
        }
        &.complimentary {
            border-color: var(--complimentary);
            color: var(--complimentary);
            fill: var(--complimentary);
            > * {
                color: var(--complimentary);
                fill: var(--complimentary);
            }
        }
        &.hover\:complimentary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: var(--complimentary);
                border-color: var(--complimentary);
                color: var(--complimentary-contrast);
                fill: var(--complimentary-contrast);
                > * {
                    color: var(--complimentary-contrast);
                    fill: var(--complimentary-contrast);
                }
            }
        }
        &.warning {
            border-color: var(--warning);
            background-color: var(--warning-tint);
            color: var(--warning-contrast-tint-1);
            fill: var(--warning-contrast-tint-1);
            > * {
                color: var(--warning-contrast-tint-1);
                fill: var(--warning-contrast-tint-1);
            }
        }
        &.hover\:warning {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: var(--warning-tint-2);
                border-color: var(--warning);
                color: var(--warning-contrast-tint-1);
                fill: var(--warning-contrast-tint-1);
                > * {
                    color: var(--warning-contrast-tint-1);
                    fill: var(--warning-contrast-tint-1);
                }
            }
        }
        &.smoke {
            border-color: var(--smoke);
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:smoke {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: var(--smoke);
                border-color: var(--smoke);
                color: var(--coal);
                fill: var(--coal);
                box-shadow: var(--shadow-base);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.steel {
            border-color: var(--steel);
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
            > .icon, > .chevron {
                color: var(--anchor);
                fill: var(--anchor);
            }
        }
        &.hover\:steel {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: var(--cloud);
                border-color: var(--steel);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
            &:active, &.active, &:focus {
                background-color: var(--smoke);
                border-color: var(--smoke);
                color: var(--coal);
                fill: var(--coal);
                box-shadow: var(--shadow-base);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.white {
            border-color: #FFF;
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:white {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background-color: #FFF;
                border-color: #FFF;
                color: var(--coal);
                fill: var(--coal);
                box-shadow: var(--shadow-base);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.contrast {
            border-width: 1.5px;
            border-color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.15)";
            color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
            fill: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
            > * {
                color: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
                fill: ~"rgba(var(--colour-rgb, var(--coal-rgb)), 0.4)";
            }
        }
        &.hover\:contrast {
            &:not(.touch):hover, &:active, &.active, &:focus {
                color: currentColor;
                fill: currentColor;
                > * {
                    color: currentColor;
                    fill: currentColor;
                }
            }
        }
        &.translucent {
            border: none;
            background-color: rgba(255, 255, 255, 0.12);
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover {
                background-color: rgba(255, 255, 255, 0.2);
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
            &:active, &.active { background-color: rgba(255, 255, 255, 0.4); }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: rgba(255, 255, 255, 0.12);
                    > * {
                        color: rgba(255, 255, 255, 0.2);
                        fill: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
        &.translucent-dark {
            border: none;
            background-color: ~"rgba(var(--coal-rgb), 0.25)";
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover {
                background-color: ~"rgba(var(--coal-rgb), 0.35)";
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
            &:active, &.active { background-color: ~"rgba(var(--coal-rgb), 0.5)"; }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: ~"rgba(var(--coal-rgb), 0.25)";
                    > * {
                        color: ~"rgba(var(--coal-rgb), 0.4)";
                        fill: ~"rgba(var(--coal-rgb), 0.4)";
                    }
                }
            }
        }
    }
    &.basic {
        padding: 8px 0;
        background: none;
        text-decoration: none;
        &.icon-right span.spinner { margin-left: 8px; }
        &.icon-left span.spinner { margin-right: 8px; }

        &.slim { padding: 4px 0; }

        &.custom {
            color: var(--button-color);
            fill: var(--button-color);
            > * {
                color: var(--button-color);
                fill: var(--button-color);
            }
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--button-hover-color);
                fill: var(--button-hover-color);
                > * {
                    color: var(--button-hover-color);
                    fill: var(--button-hover-color);
                }
            }
        }
        &.primary {
            color: var(--primary);
            fill: var(--primary);
            > * {
                color: var(--primary);
                fill: var(--primary);
            }
        }
        &.hover\:primary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--primary-shade-1);
                fill: var(--primary-shade-1);
                > * {
                    color: var(--primary-shade-1);
                    fill: var(--primary-shade-1);
                }
            }
        }
        &.navy {
            color: var(--navy);
            fill: var(--navy);
            > * {
                color: var(--navy);
                fill: var(--navy);
            }
        }
        &.hover\:navy {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--navy-shade-1);
                fill: var(--navy-shade-1);
                > * {
                    color: var(--navy-shade-1);
                    fill: var(--navy-shade-1);
                }
            }
        }
        &.complimentary {
            color: var(--complimentary);
            fill: var(--complimentary);
            > * {
                color: var(--complimentary);
                fill: var(--complimentary);
            }
        }
        &.hover\:complimentary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--complimentary-shade-1);
                fill: var(--complimentary-shade-1);
                > * {
                    color: var(--complimentary-shade-1);
                    fill: var(--complimentary-shade-1);
                }
            }
        }
        &.success {
            color: var(--success);
            fill: var(--success);
            > * {
                color: var(--success);
                fill: var(--success);
            }
        }
        &.hover\:success {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--success-shade-1);
                fill: var(--success-shade-1);
                > * {
                    color: var(--success-shade-1);
                    fill: var(--success-shade-1);
                }
            }
        }
        &.warning {
            color: var(--warning);
            fill: var(--warning);
            > * {
                color: var(--warning);
                fill: var(--warning);
            }
        }
        &.hover\:warning {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--warning-shade-1);
                fill: var(--warning-shade-1);
                > * {
                    color: var(--warning-shade-1);
                    fill: var(--warning-shade-1);
                }
            }
        }
        &.danger {
            color: var(--danger);
            fill: var(--danger);
            > * {
                color: var(--danger);
                fill: var(--danger);
            }
        }
        &.hover\:danger {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--danger-shade-1);
                fill: var(--danger-shade-1);
                > * {
                    color: var(--danger-shade-1);
                    fill: var(--danger-shade-1);
                }
            }
        }
        &.anchor {
            color: var(--anchor);
            fill: var(--anchor);
            > * {
                color: var(--anchor);
                fill: var(--anchor);
            }
        }
        &.hover\:anchor {
            &:not(.touch):hover {
                background: none;
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.active\:anchor {
            &:active, &.active, &:focus {
                background: none;
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.active\:primary {
            &:active, &.active.active {
                background: none;
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }
        &.steel {
            color: var(--steel);
            fill: var(--steel);
            > * {
                color: var(--steel);
                fill: var(--steel);
            }
        }
        &.hover\:steel {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--anchor);
                fill: var(--anchor);
                > * {
                    color: var(--anchor);
                    fill: var(--anchor);
                }
            }
        }
        &.white {
            color: #FFF;
            fill: #FFF;
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    > * {
                        color: rgba(255, 255, 255, 0.2);
                        fill: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
        &.hover\:white {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
        }
        &.translucent {
            color: rgba(255, 255, 255, 0.6);
            fill: rgba(255, 255, 255, 0.6);
            > * {
                color: rgba(255, 255, 255, 0.6);
                fill: rgba(255, 255, 255, 0.6);
            }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    > * {
                        color: rgba(255, 255, 255, 0.25);
                        fill: rgba(255, 255, 255, 0.25);
                    }
                }
            }
        }
        &.hover\:translucent {
            &:not(.touch):hover {
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
            &:active, &.active.active {
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
        }
        &.coal {
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:coal {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
        &.translucent-dark {
            color: ~"rgba(var(--coal-rgb), 0.4)";
            fill: ~"rgba(var(--coal-rgb), 0.4)";
            > * {
                color: ~"rgba(var(--coal-rgb), 0.4)";
                fill: ~"rgba(var(--coal-rgb), 0.4)";
            }
        }
        &.hover\:translucent-dark {
            &:not(.touch):hover {
                background: none;
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
            &:active, &.active {
                background: none;
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }

        &.transparent {
            background: none;
            color: rgba(255, 255, 255, 0.75);
            fill: rgba(255, 255, 255, 0.75);
            > * {
                color: rgba(255, 255, 255, 0.75);
                fill: rgba(255, 255, 255, 0.75);
            }
        }
        &.hover\:transparent {
            &:not(.touch):hover {
                background: none;
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
            &:active, &.active {
                background: none;
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }

        &.transparent-dark {
            background: none;
            color: ~"rgba(var(--coal-rgb), 0.4)";
            fill: ~"rgba(var(--coal-rgb), 0.4)";
            > * {
                color: ~"rgba(var(--coal-rgb), 0.4)";
                fill: ~"rgba(var(--coal-rgb), 0.4)";
            }
        }
        &.hover\:transparent-dark {
            &:not(.touch):hover {
                background: none;
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
            &:active, &.active {
                background: none;
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }
    }
    &.add {
        padding: 8px 0;
        justify-content: center;
        background: none;
        > .icon {
            padding: 8px;
            border-radius: 100%;
        }
        &.primary {
            color: var(--primary);
            fill: var(--primary);
            > * {
                color: var(--primary);
                fill: var(--primary);
            }
            > .icon {
                background-color: var(--primary);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
        }
        &.hover\:primary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--primary-shade-1);
                fill: var(--primary-shade-1);
                > * {
                    color: var(--primary-shade-1);
                    fill: var(--primary-shade-1);
                }
                > .icon {
                    background-color: var(--primary-shade-1);
                    > * {
                        color: var(--primary-contrast);
                        fill: var(--primary-contrast);
                    }
                }
            }
        }
        &.complimentary {
            color: var(--complimentary);
            fill: var(--complimentary);
            > * {
                color: var(--complimentary);
                fill: var(--complimentary);
            }
            > .icon {
                background-color: var(--complimentary);
                > * {
                    color: var(--complimentary-contrast);
                    fill: var(--complimentary-contrast);
                }
            }
        }
        &.hover\:complimentary {
            &:not(.touch):hover, &:active, &.active, &:focus {
                background: none;
                color: var(--complimentary-shade-1);
                fill: var(--complimentary-shade-1);
                > * {
                    color: var(--complimentary-shade-1);
                    fill: var(--complimentary-shade-1);
                }
                > .icon {
                    background-color: var(--complimentary-shade-1);
                    > * {
                        color: var(--complimentary-contrast);
                        fill: var(--complimentary-contrast);
                    }
                }
            }
        }
    }
    &.media.icononly {
        padding: 8px;
        border-radius: 100%;
        justify-content: center;
        box-shadow: none;

        background-color: ~"rgba(var(--coal-rgb), 0.8)";
        color: var(--anchor);
        fill: var(--anchor);
        > * {
            color: var(--anchor);
            fill: var(--anchor);
        }

        &.solid {
          background-color: var(--night);
          &:not(.touch):hover, &:active, &:focus, &.active, &.focus { background-color: var(--night); }
        }
        &.translucent {
            background-color: rgba(255, 255, 255, 0.12);
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover { background-color: rgba(255, 255, 255, 0.2); }
            &.active { background-color: rgba(255, 255, 255, 0.4); }
        }
        &.success {
            background-color: var(--success);
            > * {
                color: var(--success-contrast);
                fill: var(--success-contrast);
            }
        }
        &.danger {
            background-color: var(--danger);
            > * {
                color: var(--danger-contrast);
                fill: var(--danger-contrast);
            }
        }
        &.hover\:primary {
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                background-color: var(--coal);
                color: var(--primary);
                fill: var(--primary);
                > * {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
        }
        &.hover\:danger {
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                background-color: var(--danger);
                color: var(--danger-contrast);
                fill: var(--danger-contrast);
                > * {
                    color: var(--danger-contrast);
                    fill: var(--danger-contrast);
                }
            }
        }
        &.hover\:success {
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                background-color: var(--success);
                color: var(--success-contrast);
                fill: var(--success-contrast);
                > * {
                    color: var(--success-contrast);
                    fill: var(--success-contrast);
                }
            }
        }
        &.skeleton {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            box-shadow: none;
            cursor: default;
            > * { opacity: 0; }
        }
    }
    &.context.icononly {
        padding: 0;
        border-radius: 100%;
        justify-content: center;
        box-shadow: none;
        flex: 0 0 auto;

        &.translucent {
            background-color: ~"rgba(var(--coal-rgb), 0.2)";
            backdrop-filter: blur(8px);
            color: #FFF;
            fill: #FFF;
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                background-color: ~"rgba(var(--coal-rgb), 0.4)";
                color: #FFF;
                fill: #FFF;
                > * {
                    color: #FFF;
                    fill: #FFF;
                }
            }
            &:disabled, &.disabled, &.visually-disabled {
                &:not(.visually-enabled):not(.success):not(.danger) {
                    background-color: ~"rgba(var(--coal-rgb), 0.25)";
                    > * {
                        color: ~"rgba(255, 255, 255, 0.2)";
                        fill: ~"rgba(255, 255, 255, 0.2)";
                    }
                }
            }
        }
        &.translucent-contrast-light {
            background: 0;
            opacity: 0.4;
            > * {
                color: #FFF;
                fill: #FFF;
            }
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  { opacity: 1; }
        }
        &.translucent-contrast-dark {
            background: 0;
            opacity: 0.6;
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  { opacity: 1; }
        }
        &.primary {
            background-color: var(--anchor);
            color: #FFF;
            fill: #FFF;
            > * {
                color: #FFF;
                fill: #FFF;
            }
        }
        &.hover\:primary {
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                background-color: var(--primary);
                color: var(--primary-contrast);
                fill: var(--primary-contrast);
                > * {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
        }
        &.smoke {
            background-color: var(--smoke);
            color: var(--coal);
            fill: var(--coal);
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
        }
        &.hover\:smoke {
            &:not(.touch):hover, &:active, &:focus, &.active, &.focus  {
                box-shadow: var(--shadow-base);
                background-color: var(--mercury);
                color: var(--coal);
                fill: var(--coal);
                > * {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }
    }
    &.dropdown {
        border-radius: 8px;
        background: none;

        &.primary, &.smoke {
            border: 1px solid var(--smoke);
            span {
                color: var(--coal);
                fill: var(--coal);
                &.chevron {
                    color: var(--anchor);
                    fill: var(--anchor);
                }
            }
            > * {
                color: var(--coal);
                fill: var(--coal);
            }
            &.dark > * {
                color: #FFF;
                fill: #FFF;
            }
        }

        &.smoke, &.hover\:smoke {
            &.active {
                background-color: var(--mercury);
                border: 1px solid var(--mercury);
                box-shadow: var(--shadow-base);
                > *, > span {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
            &:not(.touch):hover, &:focus, &.focus {
                background-color: var(--smoke);
                border: 1px solid var(--smoke);
                > *, > span {
                    color: var(--coal);
                    fill: var(--coal);
                }
            }
        }

        &.primary, &.hover\:primary {
            &:not(.touch):not(.active):hover {
                background: none;
                border: 1px solid var(--primary);
                > *, span {
                    color: var(--primary);
                    fill: var(--primary);
                }
            }
            &.active, &:focus, &.focus {
                background-color: var(--primary);
                border: 1px solid var(--primary);
                > *, span {
                    color: var(--primary-contrast);
                    fill: var(--primary-contrast);
                }
            }
        }
    }

    &.icononly {
        min-height: 0;
        padding: 8px;
        border-radius: 8px;
        justify-content: center;
        box-shadow: none;
        &.slim {
            min-height: 0;
            padding: 4px;
            width: auto;
            height: auto;
        }
        &.skeleton {
            border-radius: 8px;
            width: 40px;
            height: 40px;
            box-shadow: none;
            cursor: default;
            > * { opacity: 0; }
        }
    }
    &:disabled, &.disabled, &.visually-disabled {
        cursor: default;
        &:not(.visually-enabled):not(.success):not(.danger) {
            box-shadow: none;
            background: var(--smoke);
            border-color: var(--smoke);
            &.basic, &.add { background: none; }
            > * {
                color: var(--steel);
                fill: var(--steel);
            }
        }
    }
    &:disabled, &.disabled {
        pointer-events: none;
    }
}
</style>
